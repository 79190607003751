import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Hidden} from '@material-ui/core';
import {HoverMenu, ScrollTop} from 'components/atoms';
import propTypes from 'helpers/propTypes';
import {Menu, Topbar} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    '& :first-letter': {
      fontKerning: 'none',
    }
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    minHeight: '100vh',
    paddingTop: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      paddingLeft: 200,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: 280,
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: 0,
      paddingLeft: 380,
      paddingRight: 100,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',

  },
}));

const DocsLayout = ({children, themeToggler, themeMode}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
    <Hidden smUp>
      <HoverMenu handleClick={() => setMobileNavOpen(true)}/>
      {/* <Topbar
        themeMode={themeMode}
        themeToggler={themeToggler}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      /> */}
      </Hidden>
      

      <Menu
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      
    </div>
  );
};

DocsLayout.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default DocsLayout;
