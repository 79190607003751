import React, {useState} from 'react';
import clsx from 'clsx';
import {Box, Typography, List, ListItem, makeStyles, Divider} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import {Link} from 'gatsby-theme-material-ui';
import propTypes from 'helpers/propTypes';
import {usePageVisits} from 'data/queries/pages';
import {usePageContext} from 'data/page-context';

const useStyles = makeStyles(theme => ({
  pageList: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  navListItem: {
    paddingBottom: 0,
    paddingTop:0,
  },
  navGroupContainer: {
    width: '100%',
  },
  navGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    backgroundColor: theme.palette.background.paper,
  },
  activeNavGroup: {
    // backgroundColor: theme.palette.primary.main,
  },
  topic: {
    fontSize: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '25px',
    },
    fontWeight: 700,
    fontKerning: 'none',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    '&:hover': {
      // color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  topicCompleted: {
    color: theme.palette.text.secondary,
  },
  topicOpen: {
    fontWeight: 900,
    '&:before': {
      transform: 'rotate(90deg)',
    },
  },
  topicActive: {
    // fontWeight: 900,
    color: theme.palette.text.contrast,
  },
  completion: {
    color: theme.palette.text.secondary,
  },
  externalLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}));

const getComponentId = () => {
  const isBrowser = typeof window !== "undefined";
  let path = "/";
  if (isBrowser)
    path = window.location.pathname;

  if (path.charAt(path.length-1) === '/')
    path = path.slice(0, -1);
  let component = path.substr(path.lastIndexOf('/'));
  if (component == '') {
    component = '/';
  }
  return component;
}

const NavTopic = ({topic, external}) => {
  const classes = useStyles();
  const theme = useTheme();

  const pageContext = usePageContext();
  const isCurrentTopic = getComponentId() === topic.slug;

  return (
    <ListItem disableGutters className={classes.navListItem}>
      <div key={topic.id} className={classes.navGroupContainer}>
        <Box
          pt={2}
          pb={1}
          fontSize={16}
          className={clsx({
            [classes.navGroup]: true,
            [classes.activeNavGroup]: isCurrentTopic,
          })}
        >
          {external ? 
            <Box pl={5} pr={1}>
              <Typography
                variant="h6"
                color="textPrimary"
                component="a"
                className={clsx({
                  [classes.topic]: true,
                })}
              >
                <a href={topic.url} className={classes.externalLink}>{topic.title}</a>
                
              </Typography>
            </Box>
          :
            <Link to={topic.slug} color="inherit" aria-label={topic.title}>
              <Box pl={5} pr={1}>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  component="a"
                  className={clsx({
                    [classes.topic]: true,
                    [classes.topicActive]: isCurrentTopic,
                  })}
                >
                  {topic.title}
                </Typography>
              </Box>
            </Link>
          }
        </Box>
        {/* <Divider /> */}
      </div>
    </ListItem>
  );
};

NavTopic.propTypes = {

};

NavTopic.defaultProps = {
  external: false,
};

export default NavTopic;
