export const modules = [
  // {
  //   title: 'Home',
  //   slug: '/home'
  // },
  {
    title: 'Home',
    slug: '/'
  },
  {
    title: 'Founder coaching',
    slug: '/coaching'
  },
  {
    title: 'Who I work with',
    slug: '/founders'
  },
  // {
  //   title: "My philosophy",
  //   slug: '/philosophy'
  // },
  {
    title: 'My background',
    slug: '/background'
  },
  {
    title: 'Strategy',
    slug: '/strategy'
  },
  {
    title: 'Culture',
    slug: '/culture'
  },
  {
    title: 'Get in touch',
    slug: '/contact'
  },
  

  // {
  //   title: 'Photos',
  //   slug: '/photos'
  // },
  // {
  //   title: 'Ongoing Projects',
  //   slug: '/projects'
  // },
  // {
  //   title: 'Music & Media',
  //   slug: '/music'
  // },
  // {
  //   title: 'Get Involved',
  //   slug: '/get-involved'
  // },
];
